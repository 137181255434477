import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Carousel from "react-bootstrap/Carousel";

// Components
import Page from "../../components/page/page";

// Services
import AuthService from "../../common/security/auth.service";

const LandingPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Page>
      <div className="container-landing">
        <div className="image">
          <Carousel>
            <Carousel.Item interval={5000}>
              <img
                className="w-50 logo-image"
                src="/images/landing/Elektricar.jpg"
                alt="Elektricar"
              />
              <Carousel.Caption>
                <p className="image-text">Održavanje</p>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item interval={5000}>
              <img
                className="w-50 logo-image"
                src="/images/landing/Elektro_odrzavanje.jpg"
                alt="Elektro odrzavanje"
              />
              <Carousel.Caption>
                <p>Ispitivanje</p>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item interval={5000}>
              <img
                className="w-50 logo-image"
                src="/images/landing/Siemens_plc_orman.jpg"
                alt="Siemens"
              />
              <Carousel.Caption>
                <p>Komandno razvodni ormani</p>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item interval={5000}>
              <img
                className="w-50 logo-image"
                src="/images/landing/ABB_regulatori_orman.jpg"
                alt="ABB"
              />
              <Carousel.Caption>
                <p>Frekventni regulatori</p>
              </Carousel.Caption>
            </Carousel.Item>
          </Carousel>
        </div>
        <div className="header-text">
          Dobrodošli na nezvanični Portal elektro službe površinskog kopa
          "Tamnava - Istočno polje"
        </div>
        {!AuthService.isAuthenticated() && (
          <div className="sign">
            <Link to="/login" className="btn btn-primary btn-lg">
              Prijava
            </Link>
            <Link to="/signup" className="btn btn-success btn-lg">
              Registracija
            </Link>
          </div>
        )}
      </div>
    </Page>
  );
};

export default LandingPage;
